<template>
  <div>
      <SvgLine v-if="showMapType===1" ref="svgline" @map-finished="handleMapLoadFinished" @on-click-station="handleClickStation" :currentCategory="categoryId"/>

      <GeoMap v-else ref="geoMap" @map-finished="handleMapLoadFinished" @on-click-station="handleGeoMapClickStation"/>

      <SvgStation ref="svgStation" :stationSvgModal.sync="stationSvgModal" :stationId="chooseStationId" :svgShowType="1"
        :assetIds="searchCondition.assetIds" :searchSchedules="searchSchedule" showStationPortray
        @svg-finished="svgFinished" @handleChange3dMode="handleBeginRevice3d"/>

      <ThreeStation ref="threeStation" @handleChangeSvgMode="handleClickStation"/>

      <!-- 显示站点资源详情，用于路面媒体 -->
      <station-resource ref="stationResource"/>
  </div>
</template>

<script>
import SvgLine from '@/components/svg/SvgLine'
import SvgStation from '@/components/svg/SvgStation'
import ThreeStation from '@/components/svg/ThreeStation'

import GeoMap from '@/components/map/GeoMap'
import StationResource from '../resourceComponent/StationResource'

import { ParseDate } from '@/utils/dateFormat'

export default {
  components: {
    SvgLine, GeoMap, SvgStation, ThreeStation, StationResource
  },
  data () {
    return {
      showMapType: this.$store.getters.token.publisherSetting.mediaType,
      mapFinished: false,
      stationSvgModal: false, // 弹窗显示SVG站点地图
      chooseStationId: 0
    }
  },
  methods: {
    handleMapLoadFinished (val) {
      this.mapFinished = val
    },
    LoadMapData () {
      if (this.mapFinished) { // 验证地图是否加载完毕， 否则延迟1秒再次请求
        if (this.mapType === 'flicker') {
          if (this.showMapType === 1) {
            this.$refs.svgline.loadFlickerStations(this.stations)
          } else {
            this.$refs.geoMap.loadFlickerStations(this.stations)
          }
        } else {
          if (this.showMapType === 1) {
            this.$refs.svgline.loadResourceStations(this.stations)
          } else {
            this.$refs.geoMap.loadResourceStations(this.stations)
          }
        }
      } else {
        setTimeout(() => {
          this.LoadMapData()
        }, 1000)
      }
    },
    hideMapOtherAsset (assetIds) {
      if (this.mapFinished) {
        // 仅限地铁线路图，需要隐藏其他线路
        if (this.showMapType === 1) {
          this.$refs.svgline.hideOtherAsset(assetIds)
        }
      } else {
        // 验证地图是否加载完毕， 否则延迟1秒再次请求
        setTimeout(() => {
          this.hideMapOtherAsset(assetIds)
        }, 1000)
      }
    },
    handleClickStation (stationId) { // 地图点击站点事件
      this.chooseStationId = stationId
      this.stationSvgModal = true
    },
    handleGeoMapClickStation (stationId) {
      this.$refs.stationResource.showStationResource(stationId)
    },
    svgFinished () { // SVG站点地图加载完毕
      this.$refs.svgStation.initShowBrand()
      this.$refs.svgStation.choseSpinShow()
    },
    handleBeginRevice3d (stationId) {
      this.$refs.threeStation.initData(stationId)
    }
  },
  computed: {
    categoryId () {
      return this.$store.state.stock.categoryId
    },
    beginUpdate () {
      return this.$store.state.stock.beginUpdate
    },
    stations () {
      return this.$store.state.stock.stations
    },
    mapType () {
      return this.$store.state.stock.mapType
    },
    searchCondition () {
      return this.$store.state.stock.searchCondition
    },
    searchSchedule () {
      return [{ startDate: ParseDate(this.searchCondition.startDate), endDate: ParseDate(this.searchCondition.endDate) }]
    },
    mapShowAssets () {
      return this.$store.state.stock.showAssets
    }
  },
  watch: {
    beginUpdate (val) {
      this.LoadMapData()
    },
    mapShowAssets (val) {
      this.hideMapOtherAsset(val)
    }
  }

}
</script>
